
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import './custom-palettes';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$doxaexpression-frontend-primary: mat.define-palette(mat.$indigo-palette);
$doxaexpression-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$doxaexpression-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$doxaexpression-frontend-theme: mat.define-light-theme((
  color: (
    primary: $doxaexpression-frontend-primary,
    accent: $doxaexpression-frontend-accent,
    warn: $doxaexpression-frontend-warn,
    orange: #F8C72D,
    orange-light: #FCD562,
    green-1: #145F81,
    white: #FFFFFF,
    black: #000000,
  )
));

mat-checkbox:focus {
  background-color: #000000;
}


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($doxaexpression-frontend-theme);

/* You can add global styles to this file, and also import other style files */


@import '~@angular/cdk/overlay-prebuilt.css';

html, body { height: 100%; }

body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
}

label {
  color: #145F81;
  &.mat-checkbox-layout {
    background-color: white;
    span.mat-checkbox-background {
      background-color: white !important;
      svg {
        path.mat-checkbox-checkmark-path {
          stroke: #145F81 !important;
        }
      } 
    }
  }
}


// mat-label {
  
// }

// .mat-form-field-outline {
//   background-color: white;
// }

.mat-toolbar {
  background-color: white;
}

mat-form-field {
  .mat-form-field-outline {
    background-color: white;
    border-radius: 5px;
  }

  .mat-form-field-label-wrapper {
    top: -27px;
    left: -10px;

    mat-label {
      font-size: 22px;
      color: #145F81;
    }
  }
}

.column-layout {
  text-align: center;
}

.padding-layout {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;

  @media screen and (max-width: 959.9px) {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
  }
}

.text-body-0 {
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
}

.text-body-1 {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
}

.text-body-2, mat-label {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

.text-body-3 {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.text-body-underlined {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-underlined {
  text-decoration: underline;
}

.remove-underlined-link {
  text-decoration: none;
}

.text-bold {
  font-weight: bold;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-color-white {
  color: white;
}

.button-app {
  width: 90%;
  height: 60px;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
}

.divider {
  background-color: #000000;
  // width: 86%;
  // margin-left: auto;
  // margin-right: auto;
}
