.info-page {
    .title-bar {
        background-color: #3cb0d7;
        text-align: center;
        font-size: 36px;
        font-weight: 500;
        letter-spacing: 0;
        color: white;
        line-height: 1;
        padding: 1em 0;
    }

    .padding-layout {
        @media screen and (min-width: 960px) {
            padding: 0 15%;
        }
    }

    p {
        font-size: 15px;
        letter-spacing: 0;
        line-height: 20px;
    }

    h1,
    h2 {
        color: #145f81;
    }

    h1 {
        font-size: 32px;
        letter-spacing: 0;
        font-weight: bold !important;
        margin-top: 16px !important;
        margin-bottom: 0px !important;
    }

    ul {
        @media screen and (max-width: 959.9px) {
            padding: 15px;
        }
    }

    ol {
        list-style: decimal-leading-zero;
        counter-reset: item;
        li {
            counter-increment: item;
            ol {
                list-style: none;
                counter-reset: item;
                li::before {
                    content: counters(item, ".") " ";
                }
            }
        }

        @media screen and (max-width: 959.9px) {
            padding-left: 25px;

            li {
                margin-bottom: 10px;
            }

            ol {
                padding-top: 5px;
                padding-left: 10px;
            }
        }
    }
}
